import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "moneygeek-ui-components"
    }}>{`MoneyGeek UI Components`}</h2>
    <p><img alt="npm" src="https://img.shields.io/npm/v/@moneygeek/ui-components?color=green&label=%40moneygeek%2Fui-components&style=flat-square" /></p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`The MoneyGeek ui-components library is a set of react based DOM components aimed to increase the features of web pages and blog posts of the MoneyGeek websites. This library currently have available the next components to use:`}</p>
    <ul>
      <li parentName="ul">{`Best Ranking`}</li>
      <li parentName="ul">{`Button`}</li>
      <li parentName="ul">{`Column`}</li>
      <li parentName="ul">{`Expand/Collapse`}</li>
      <li parentName="ul">{`Icon`}</li>
      <li parentName="ul">{`OrderedList`}</li>
      <li parentName="ul">{`Quote`}</li>
      <li parentName="ul">{`Quote Widget`}</li>
      <li parentName="ul">{`PremiumRanking`}</li>
    </ul>
    <h3 {...{
      "id": "installation"
    }}>{`Installation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i -S @moneygeek/ui-components
`}</code></pre>
    <p>{`The previous command would install these components in a NodeJS project and they could be used like in the following example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "harmony",
        "harmony": true
      }}>{`import { Button } from "@moneygeek/ui-components";

<Button handleClick={() => alert("Clicked!")}>MG Button</Button> 
`}</code></pre>
    <h2 {...{
      "id": "development"
    }}>{`Development`}</h2>
    <p>{`Each component contains three different sections, the component, styles and the unit tests. For styling we are using the React based `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` library, for unit tests we are using `}<inlineCode parentName="p">{`jest`}</inlineCode>{` and `}<inlineCode parentName="p">{`eslint`}</inlineCode>{` as the project linter. `}</p>
    <h3 {...{
      "id": "setup"
    }}>{`Setup`}</h3>
    <p>{`If you are using Unix like systems to install the right version of NodeJS it is convenient to use the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nvm install
`}</code></pre>
    <p>{`If you are using Windows based systems you can download the last LTS version of NodeJS from its website (`}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`https://nodejs.org/en/`}</a>{`).`}</p>
    <p>{`Once NodeJS is installed you can start adding the project modules using the next command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i
`}</code></pre>
    <p>{`If you want to use `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`npm`}</inlineCode>{` you could install it using the next command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i -g yarn
`}</code></pre>
    <p>{`You could install the project libraries through `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` using the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn
`}</code></pre>
    <h3 {...{
      "id": "bulding-the-docz-development-bundle"
    }}>{`Bulding the Docz Development Bundle`}</h3>
    <p>{`To build the documentation of the existing components you need to run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run docz:dev
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn docz:dev
`}</code></pre>
    <p>{`This would generate a local website in the `}<inlineCode parentName="p">{`http://localhost:3000`}</inlineCode>{` address with the components that exist currently in the project.`}</p>
    <h3 {...{
      "id": "building-the-project-and-running-all-the-test-suite-with-linters-included"
    }}>{`Building the project and running all the test suite with linters included`}</h3>
    <p>{`To run the whole project Continuous Integration you can use the next command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run build:ci
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn build:ci
`}</code></pre>
    <p>{`This would show you if there are any linter errors and whether all the unit tests passed successfully.`}</p>
    <h3 {...{
      "id": "running-only-the-unit-tests"
    }}>{`Running only the unit tests`}</h3>
    <p>{`If you only need to run the unit tests you could use the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm test
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn test
`}</code></pre>
    <h3 {...{
      "id": "running-the-linters"
    }}>{`Running the linters`}</h3>
    <p>{`If you only need to run the linter (Eslint) you can run it using the next command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run lint
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn lint
`}</code></pre>
    <h3 {...{
      "id": "building-parcel-transpiler-and-docz-source-code"
    }}>{`Building Parcel transpiler and Docz source code`}</h3>
    <p>{`For building Parcel and Docz transpilers you can use the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run build
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn build
`}</code></pre>
    <h3 {...{
      "id": "building-parcel"
    }}>{`Building Parcel`}</h3>
    <p>{`For building Parcel you could use the next command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run parcel:build
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn parcel:build
`}</code></pre>
    <h3 {...{
      "id": "building-docz"
    }}>{`Building Docz`}</h3>
    <p>{`For building Docz you could use the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run docz:build
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn docz:build
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      